
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/fluture.png'
import "../css/ImgInvitatie.css"
import background from "../images/h9.jpg"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4"  >
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <h5>
                        Sunt frumoasă ca o floare <br/>
Sau ca o rază de soare<br/>
Și cuminte, și isteață,<br/>
O să am succes în viață!<br/>
Mă numesc:</h5>
<h4>Amelia</h4>
<h5>și vă invit alături de părinții mei:</h5>
  <h4> Virginia și Sorin,</h4> 
   <h5> nașii:</h5>
   <h4>Lavinia și Sorin</h4>
   <h5>și moșii:</h5>
   <h4>Daniela și Marius</h4> 
   <h5>  să împărtășim împreună<br/>
binecuvântarea botezului în data de:</h5>
<h4>23.07.2023</h4>
<h5>la Biserica</h5>
<h4>"Sfinții Împărații Constantin și Elena",</h4>
<h5>Strada Oltului, Roșiori de Vede, la ora 18:00.</h5>
<h5>Acest eveniment important pentru noi<br/>
îl vom serba la Restaurantul</h5>
<h4>"Antonia Events"</h4>
<h5>din Coşoteni,
începând cu ora 20:00</h5>
                          
                      
                              <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                       
                        
                        
                  
                        <img id="mikey_img" src={mikey} />
                       
                      


                        {/** <ImgInvitatie />*/}

                        <h4 class="card-footer">Vă așteptăm cu drag!</h4>
                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>
                    </div>




                </div>
            </div>
        </section>

    )
}

export default CardInvitatie;